<template>
    <div>
        <van-collapse v-model="activeNames" accordion>
            <van-collapse-item
                    v-for="(yearItem,yearIndex) in arrearDetailList"
                    :key="yearIndex"
                    :name="yearIndex"
                    :title="yearItem.billingYear + '年'"
                    :value="'合计： ￥' +yearItem.totalBillingMoney"
                    size="large"
                    value-class="yearCollapse"
            >
                <van-cell-group v-for="subjItem in yearItem.list" :key="subjItem.index">
                    <van-list v-bind="subjItem">
                        <div style="width: 100%;">
                            <van-cell
                                    :title="subjItem.subjName"
                                    :value="'小计：￥' + subjItem.totalBillingMoney"
                                    size="large"
                                    value-class="yearCollapse"
                                    class="subjCell"
                                    :border="false"
                            >
                                <template slot="label">
                                    <div class="monthDiv" v-for="(monthItem, monthIndex) in subjItem.list">
                                        <span style="display:block;">{{monthItem.billingMonth}}月</span>
                                        <span style="display:block;">￥{{monthItem.billingMoney}}</span>
                                    </div>
                                </template>
                            </van-cell>
                        </div>
                    </van-list>
                </van-cell-group>
            </van-collapse-item>
        </van-collapse>

    </div>
</template>

<script>
    import {listArrearDetailsByCollapse} from "../../api/api.js";


    export default {
        name: "detailsOfOwnersArrears",
        data() {
            return {
                activeNames: [],
                keyword:'',
                linkDataId:'',
                mobile:'',
                page:'',
                projId:'',
                rows:'',
                arrearDetailList: [],
            }
        },

        mounted() {
            this.keyword = this.$route.query.keyword;
            this.linkDataId = this.$route.query.linkDataId;
            this.mobile = this.$route.query.mobile;
            this.page = this.$route.query.page;
            this.rows = this.$route.query.rows;
            this.projId = this.$route.query.projId;
            this.listByYears();
        },

        methods: {

            async listByYears() {
                let arr = {
                    keyword:this.keyword,
                    linkDataId:this.linkDataId,
                    mobile:this.mobile,
                    page:this.page,
                    projId:this.projId,
                    rows:this.rows
                }
                let {data: res} = await listArrearDetailsByCollapse(arr);
                this.arrearDetailList = res;
            },
        }
    }
</script>

<style scoped>

    .yearCollapse {
        color: red;
        font-size: 16px;
    }

    .monthDiv {
        font-size: 16px;
        color: #123555;
        width: 200%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 12px 0;
    }

    .subjCell{
        margin-top: 10px;
    }
</style>