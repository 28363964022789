<template>
  <div>
    <div class="a">
      <div class="search">
        <input v-model="search" placeholder="请输入搜索内容" />
        <span @click="search1">搜索</span>
      </div>
    </div>
    <!-- 搜索后 -->
    <div v-if="searchData.length>0" class="mar">
      <!-- <div class="box" v-for="(item,index) in searchData" :key="index"> -->
      <div
        v-for="(item,index) in searchData"
        :key="index"
        :class="jsColor[index-1] < 7 ? 'boxac' : 'box'"
        @click="showBtn(item)"
      >
        <div class="top">
          <h2>合同号：{{item.lcNO}}</h2>
        </div>
        <div class="cen">
          <p>合同金额：{{item.billingMoney}}</p>
          <p>欠费金额：{{item.arrMoney}}</p>
          <p>已交金额：{{item.recMoney}}</p>
        </div>
        <div class="bot">
          <p>到期时间：{{item.htjsrq}}</p>
          <!-- <p v-if="parseInt(item.htzt) == 1">合同状态：创建中</p>
                    <p v-if="parseInt(item.htzt) == 2">合同状态：预定合同</p>
                    <p v-if="parseInt(item.htzt) == 3">合同状态：正常履行</p>
                    <p v-if="parseInt(item.htzt) == 4">合同状态：暂停履行</p>
          <p v-if="parseInt(item.htzt) == 5">合同状态：终止履行</p>-->
        </div>
      </div>
    </div>
    <!-- 搜索前 -->
    <div v-else class="mar">
      <div
        v-for="(item,index) in comArry"
        :key="index"
        :class="jsColor[index-1] < 400 ? 'boxac' : 'box'"
        @click="showBtn(item)"
      >
        <div class="top">
          <h2>合同号：{{item.lcNO}}</h2>
        </div>
        <div class="cen">
          <p>合同金额：{{item.billingMoney}}</p>
          <p>欠费金额：{{item.arrMoney}}</p>
          <p>已交金额：{{item.recMoney}}</p>
        </div>
        <div class="bot">
          <p>到期时间：{{item.htjsrq}}</p>
          <p v-if="parseInt(item.htzt) == 1">合同状态：创建中</p>
          <p v-if="parseInt(item.htzt) == 2">合同状态：预定合同</p>
          <p v-if="parseInt(item.htzt) == 3">合同状态：正常履行</p>
          <p v-if="parseInt(item.htzt) == 4">合同状态：暂停履行</p>
          <p v-if="parseInt(item.htzt) == 5">合同状态：终止履行</p>
        </div>
      </div>
    </div>
    <div class="aaa" v-show="showAaa">
      <div class="aab">
        <p>
          <span>合同签订时间：</span>
          <span>{{arrData.htksrq}}</span>
        </p>
        <p>
          <span>乙方联系人：</span>
          {{arrData.yflxr}}
          <span></span>
        </p>
        <p>
          <span>电话：</span>
          <span>{{arrData.yflxdh}}</span>
        </p>
        <p>
          <span>房源信息：</span>
          <span>{{arrData.czfyName}}</span>
        </p>
        <p>
          <span>合同开始日期：</span>
          <span>{{arrData.htksrq}}</span>
        </p>
        <p>
          <span>结束日期：</span>
          <span>{{arrData.htjsrq}}</span>
        </p>
        <p>
          <span>合同总额：</span>
          <span>{{arrData.htjhysje}}</span>
        </p>
        <p>
          <span>已收金额：</span>
          <span>{{ysje}}</span>
        </p>
        <p>
          <span>应收金额：</span>
          <span>{{ysjine}}</span>
        </p>
        <h1 @click="clearBtn">确定</h1>
        <h2></h2>
      </div>
    </div>
  </div>
</template>

<script>
import { findLeaseInfo,findLeaseInfoById } from "../../api/api.js";

export default {
  data() {
    return {
      mobile: "",
      comArry: "",
      searchData: "",
      htzt: "3", //合同状态，若多个，格式为"3,4,5"，以“,”连接
      htjsriqi: [],
      jsColor: [],
      showAaa: false,
      ysje:'',//已收金额
      ysjine:'',//应收金额
      arrData:""//合同详情
    };
  },
  created() {
    this.findLeaseInfoData();
  },
  methods: {
    //关闭合同详情按钮
    clearBtn() {
      this.showAaa = false;
    },
    //获取合同详情
    async showBtn(id) {
      this.showAaa = true;
      this.ysje = id.recMoney;
      this.ysjine = id.billingMoney;
      const result = await findLeaseInfoById(id.id);
      this.arrData = result.data
      console.log(result);
    },
    async findLeaseInfoData() {
      let lxdh = this.$store.state.userPhone;
      if (lxdh == "") {
        this.$store
          .dispatch("fetchUserInfo")
          .then(res => {
            lxdh = res.data.mobilePhone;
            this.mobile = lxdh;

            let ary = {
              lxdh: lxdh,
              htzts: this.htzt,
              userType:"WORKER"
            };
            this.findLeaseInfo(ary);
          })
          .catch(err => {
            console.log(err.message);
          });
      } else {
        let ary = {
          lxdh: lxdh,
          htzts: this.htzt,
          userType:"WORKER"
        };
        this.findLeaseInfo(ary);
      }
    },
    Search() {
      // search 是 v-model="search" 的 search
      var search = this.search;
      if (search) {
        this.searchData = this.comArry.filter(function(product) {
          // 每一项数据
          // console.log(product)
          return Object.keys(product).some(function(key) {
            // 每一项数据的参数名
            // console.log(key)
            return (
              String(product[key])
                // toLowerCase() 方法用于把字符串转换为小写。
                .toLowerCase()
                // indexOf() 方法可返回某个指定的字符串值在字符串中首次出现的位置。
                .indexOf(search) > -1
            );
          });
        });
      } else if (search.length === 0) {
        this.searchData = this.comArry;
      } else {
        return this.searchData;
      }
    },
    async search1() {
      let ary = {
        lxdh: this.mobile,
        queryCondition: this.search,
        htzt: this.htzt,
        userType:"WORKER"
      };
      try {
        const res = await findLeaseInfo(ary);
        this.comArry = res.data;
        console.log(this.comArry);
      } catch (err) {
        console.log(err);
      }
    },

    async findLeaseInfo(ary) {
      let timestamp = new Date().getTime();
      const result = await findLeaseInfo(ary);
      this.comArry = result.data;
      // 合同到期时间
      for (let i = 0; i < this.comArry.length; i++) {
        this.htjsriqi.push(this.comArry[i].htjsrq);
      }
      let htjsriq = [];
      for (let i = 0; i < this.htjsriqi.length; i++) {
        htjsriq.push(Date.parse(this.htjsriqi[i]));
        // console.log(htjsriq)
      }
      for (let i = 0; i < htjsriq.length; i++) {
        this.jsColor.push((htjsriq[i] - timestamp) / 1000 / 60 / 60 / 24);
      }
    }
  }
};
</script>

<style scoped>
.aaa {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.aab {
  margin: 0 auto;
  position: relative;
  top: 20px;
  width: 80%;
  left: 50%;
  margin-left: -40%;
  background: #fff;
  border-radius: 10px;
}
.aab p {
  padding: 10px 10px 5px 5px;
}
.aab h1 {
  width: 60%;
  margin: 0 auto;
  color: #fff;
  background: pink;
  text-align: center;
  font-size: 22px;
  border-radius: 5px;
}
.aab h2 {
  height: 10px;
  width: 100%;
}
.boxac {
  color: #db7093;
  background: #ccc;
  width: 96%;
  margin: 10px auto 5px;
  background: url("../../assets/contract.jpg") no-repeat left;
  background-size: 100% 103px;
}
.a {
  height: 60px;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  background: #fff;
  position: fixed;
}

.search {
  position: fixed;
  width: 96%;
  margin: 0% 2%;
  padding-top: 0%;
  position: fixed;
  display: flex;
  display: flex;
  padding: 10px 0;
}

.search input {
  display: inline-block;
  flex: 4;
}

.search span {
  flex: 1;
  text-align: center;
  line-height: 38px;
  background: palevioletred;
  color: #fff;
}

.mar {
  padding-top: 44px;
}

.box {
  background: #ccc;
  width: 96%;
  margin: 10px auto 5px;
  background: url("../../assets/contract.jpg") no-repeat left;
  background-size: 100% 103px;
}

.top h2 {
  font-size: 18px;
  padding: 5px;
}

.cen {
  display: flex;
  justify-content: space-evenly;
  font-size: 13px;
}

.bot {
  display: flex;
  justify-content: space-between;
  padding: 2px 10px 2px 15px;
  font-size: 13px;
}
</style>
