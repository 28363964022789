<template>
    <div>
        <form action="/" class="formAction">
            <van-search
                    v-model="keyword"
                    show-action
                    placeholder="房间号、客户名称回车搜索"
                    background="#EAEEF1"
                    @search="onSearch"
            >

                <div slot="action" style="background:#00FFFF" @click="moreSearch">
                    更多查询
                </div>
            </van-search>
        </form>
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                offset="300"
                :error.sync="error"
                error-text="请求失败，点击重新加载"
                class="vantList"
        >
            <van-row type="flex" class="title_row">
                <van-col span="3"></van-col>
                <van-col span="6">项目名称</van-col>
                <van-col span="6">欠费户数</van-col>
                <van-col span="8">欠费金额(元)</van-col>
            </van-row>
            <div class="container_div">
                <van-cell v-for="item in list" is-link :to="`/employee/detailsOfArrears/${item.projId}`">
                    <van-row type="flex" class="container_row">
                        <van-col span="2">
                            <van-icon name="wap-home-o" color="#FFCB01" size="1.3rem"/>
                        </van-col>
                        <van-col span="7" class="van-ellipsis">{{item.projName}}</van-col>
                        <van-col span="6" offset="1">{{item.arrearsCount}}</van-col>
                        <van-col span="8" class="item_money">￥{{item.totalBillingMoney}}</van-col>
                    </van-row>
                </van-cell>
                <van-row type="flex">
                    <van-col span="3"></van-col>
                    <van-col span="6"></van-col>
                    <van-col span="6">合计</van-col>
                    <van-col span="6" class="item_money">￥{{totalMoney}}</van-col>
                </van-row>
            </div>
        </van-list>
    </div>
</template>

<script>
    import {listProjectArrears} from "../../api/api.js"

    export default {

        data() {
            return {
                page: 1,
                rows: 10,
                list: [],
                loading: false,
                finished: false,
                keyword: '',
                totalMoney: '',
                total: 0,				// 数据总条数
                mobile: ''
            };
        },
        mounted() {
            this.initArr();
        },
        methods: {
            initArr() {
                this.list = [];
                this.mobile = this.$store.state.userPhone;

                if (this.mobile == "") {
                    this.$store
                        .dispatch("fetchUserInfo")
                        .then(res => {
                            this.mobile = res.data.mobilePhone;
                        })
                        .catch(err => {
                            console.log(err.message);
                        });
                }
            },

            // 获取列表数据方法
            async getList() {
                let arr = {
                    mobile: this.mobile,
                    page: this.page,
                    rows: this.rows
                }

                let {data: res} = await listProjectArrears(arr);

                // 判断获取数据条数若等于0
                if (res.rows.length === 0) {
                    this.list = [];				// 清空数组
                    this.finished = true;		// 停止加载
                }

                // 若数据条数不等于0
                this.total = res.total;		// 给数据条数赋值
                this.list.push(...res.rows)	// 将数据放入list中
                let array = this.list;
                this.totalMoney = '';
                let totalSum = array.reduce(function (total, currentValue, currentIndex, arr) {
                    return Number(total) + Number(currentValue.totalBillingMoney);
                }, 0);
                this.totalMoney = totalSum.toFixed(2);
                this.loading = false;			// 加载状态结束
                // 如果list长度大于等于总数据条数,数据全部加载完成
                if (this.list.length >= res.total) {
                    this.finished = true;		// 结束加载状态
                }
            },

            // 被 @load调用的方法
            onLoad() {
                // 若加载条到了底部
                // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
                let timer = setTimeout(() => {
                    this.getList();					// 调用上面方法,请求数据
                    this.page++;					// 分页数加一
                    this.finished && clearTimeout(timer);//清除计时器
                }, 100);
            },

            onSearch(val) {
                this.$router.push({
                    path: "/employee/detailsOfArrears",
                    query: {
                        projId: '',
                        mobile: this.mobile,
                        keyword: val,
                        routeFrom: "arrears"
                    }
                });
            },

            moreSearch() {
                this.$router.push({
                    path: "/employee/moreSearch",
                    query: {
                        mobile: this.mobile,
                        routeFrom: "arrears"
                    }
                });
            }

        },
    }
</script>

<style scoped>

    .formAction {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
    }

    .title_row {
        color: #123555;
        font-size: 1.2em;
        position: fixed;
        z-index: 100;
        width: 100%;
        background-color: #EAEEF1;
    }

    .item_money {
        color: red;
    }

    .vantList {
        position: absolute;
        margin-top: 45px;
        width: 100%;
    }

    .container_row {
        font-size: 1.2em;
    }

    .container_div {
        position: relative;
        margin-top: 30px;
    }

</style>
